* {
  margin: 0;
  padding: 0;
  font-family: $text-font-stack;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  position: relative;
  min-height: 100%;
}

body {
  font-size: 1.4em;
}

a {
  text-decoration: none;
  color: $text-color;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

label {
  display: inline-block;
  font-weight: 500;
}