.header {
  &-container {
    display: flex;
    align-items: center;
    padding: 16px;
    color: #fff;
    background-color: #24292f;
    width: 100%;
    height: 70px;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    height: 38px;
  }
}
