// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// All colors base.
// -----------------------------------------------------------------------------

$grey-00: #ffffff;
$grey-05: #f9f9f9;
$grey-10: #f2f2f2;
$grey-20: #ededed;
$grey-30: #e6e6e6;
$grey-40: #a6a6a6;
$grey-50: #6b6b6b;
$grey-60: #3b3b3b;
$grey-70: #303030;
$grey-80: #2b2b2b;
$grey-85: #262626;
$grey-90: #212121;
$orange-10: #fff1eb;
$orange-20: #ffd1be;
$orange-30: #ffb091;
$orange-40: #ff8e64;
$orange-50: #ff6c37;
$orange-60: #e05320;
$orange-70: #d23f0e;
$orange-80: #a12700;
$orange-90: #553326;
$blue-10: #e7f0ff;
$blue-20: #adcdfb;
$blue-30: #74aef6;
$blue-40: #3e92f2;
$blue-50: #097bed;
$blue-60: #0265d2;
$blue-70: #0053b8;
$blue-80: #00439d;
$blue-90: #002d70;
$red-10: #ffebe7;
$red-20: #fbc3ba;
$red-30: #f79a8e;
$red-40: #f37264;
$red-50: #eb2013;
$red-60: #d10d03;
$red-70: #b70700;
$red-80: #9c0400;
$red-90: #591b08;
$green-10: #e5fff1;
$green-20: #a4eec4;
$green-30: #6bdd9a;
$green-40: #38cc74;
$green-50: #0cbb52;
$green-60: #049d40;
$green-70: #007f31;
$green-80: #006024;
$green-90: #013614;
$yellow-10: #fff9e0;
$yellow-20: #fff4be;
$yellow-30: #ffe47e;
$yellow-40: #ffcf3f;
$yellow-50: #ffb400;
$yellow-60: #de9c00;
$yellow-70: #ad7a03;
$yellow-80: #886000;
$yellow-90: #523a03;
$pink-10: #fff2f9;
$pink-20: #ffd8ed;
$pink-30: #fda4d4;
$pink-40: #f15eb0;
$pink-50: #ca2583;
$pink-60: #a61468;
$pink-70: #7b0a4b;
$pink-80: #63073b;
$pink-90: #47042a;
$purple-10: #f7f2ff;
$purple-20: #e4d8f6;
$purple-30: #c0a8e1;
$purple-40: #926cc2;
$purple-50: #784fa9;
$purple-60: #623497;
$purple-70: #4c207d;
$purple-80: #35115c;
$purple-90: #250447;
$teal-10: #f2feff;
$teal-20: #d0f9fd;
$teal-30: #8eeef7;
$teal-40: #3fd6e6;
$teal-50: #0ca4b6;
$teal-60: #048192;
$teal-70: #015965;
$teal-80: #02444d;
$teal-90: #032f36;

// -----------------------------------------------------------------------------
// Text colors.
// -----------------------------------------------------------------------------

$text-color: #495057;
$color-success: $green-50;
$color-warning: $yellow-50;
$color-danger: $red-50;
$color-link: $blue-50;

// -----------------------------------------------------------------------------
// Font stacks.
// -----------------------------------------------------------------------------

$text-font-stack: 'Poppins', 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

// -----------------------------------------------------------------------------
// Others.
// -----------------------------------------------------------------------------

$border-radius-default: 4px;
