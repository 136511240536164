.table {
    border-collapse: collapse;
    width: 100%;

    tr {
        border-bottom: 0.5px solid #d5d5d5;
    }

    th, td {
        padding: 8px;
    }

    & thead {
        & th {
            font-size: 1.2rem;
            font-weight: 600;
            text-align: left;
        }
    }

    & tbody {
        & td {
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
}
