.alert {

  position: relative;
  padding: 1.2rem 1.7rem;
  border: 1px solid transparent;
  border-radius: $border-radius-default;
  text-align: left;
  margin-bottom: 8px;

  &-primary {
    color: #33428a;
    background-color: #dde2fa;
    border-color: #ccd4f8;

    & > .alert-link {
      color: #29356e;
    }
  }

  &-secondary {
    color: #464855;
    background-color: #e3e4e8;
    border-color: #d5d7dd;

    & > .alert-link {
      color: #383a44;
    }
  }

  &-success {
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;

    & > .alert-link {
      color: #195e45;
    }
  }

  &-warning {
    color: #916c2e;
    background-color: #fcf0db;
    border-color: #fbe9c9;

    & > .alert-link {
      color: #745625;
    }
  }

  &-danger {
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;

    & > .alert-link {
      color: #753333;
    }
  }

  &-info {
    color: #306391;
    background-color: #dcedfc;
    border-color: #cbe4fb;

    & > .alert-link {
      color: #264f74;
    }
  }

  &-link {
    font-weight: 600;
  }

  &-closable {
    padding-right: 3.75rem;
  }

  & + & {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

}