.notification {

  &-wrapper {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 400px;
    z-index: 9999;
  }

  &-item {
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin-top: 20px;
    overflow: hidden;
  }

  &-open {
    animation: slide-left 0.5s;
    animation-fill-mode: forwards;
  }

  &-close {
    animation: slide-right 0.5s;
    animation-fill-mode: forwards;
  }

  &-success {
    color: #fff;
    background-color: #34c38f;
    border-color: #34c38f;
  }

  &-warning {
    color: #fff;
    background-color: #f1b44c;
    border-color: #f1b44c;
  }

  &-error {
    color: #fff;
    background-color: #f46a6a;
    border-color: #f46a6a;
  }

  &-message {
    margin: 0;
    padding: 15px 10px;
    font-weight: 500;
  }

  &-bar {
    height: 5px;
    background-color: grey;
  }

  &-icon {
    width: 28px;
    height: 28px;
    margin-left: 10px;

    &-success {
      color: #fff;
    }

    &-warning {
      color: #fff;
    }

    &-danger {
      color: #fff;
    }
  }

}

@keyframes slide-left {
  0% {
    margin-left: 120%;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes slide-right {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 120%;
  }
}
