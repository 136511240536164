.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

.title {
  font-weight: 300;
  font-size: 2.2rem;
  letter-spacing: 0.1px;
}

.action {
  &-wrapper {
    display: inline-block;
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;

    &-right {
      margin-right: 10px;
    }
    &-left {
      margin-left: 10px;
    }
  }
}

.fill {
  width: 100%;
}

.align {
  &-left {
    text-align: left !important;
  }
  &-center {
    text-align: center !important;
  }
  &-right {
    text-align: right !important;
  }
}

.text {
  &-white {
    color: #fff !important;
  }
  &-black {
    color: #000 !important;
  }
  &-success {
    color: $color-success !important;
  }
  &-warning {
    color: $color-warning !important;
  }
  &-danger {
    color: $color-danger !important;
  }
  &-link {
    color: $color-link !important;
  }
}

.bg {
  &-white {
    background-color: #fff !important;
  }
  &-black {
    background-color: #000 !important;
  }
  &-success {
    background-color: $color-success !important;
  }
  &-warning {
    background-color: $color-warning !important;
  }
  &-danger {
    background-color: $color-danger !important;
  }
  &-link {
    background-color: $color-link !important;
  }
}

.mr-9 {
  margin-right: 9px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.container {
  padding-right: 1rem;
  padding-left: 1rem;
}
.container:not(.is-fluid) {
  margin: 0 auto;
}
@media only screen and (min-width: 600px) {
  .container:not(.is-fluid) {
    width: 100%;
    max-width: 570px;
  }
}
@media only screen and (min-width: 900px) {
  .container:not(.is-fluid) {
    width: 100%;
    max-width: 860px;
  }
}
@media only screen and (min-width: 1200px) {
  .container:not(.is-fluid) {
    width: 100%;
    max-width: 1150px;
  }
}
@media only screen and (min-width: 1800px) {
  .container:not(.is-fluid) {
    width: 100%;
    max-width: 1400px;
  }
}

.container-sm {
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .container-sm {
    max-width: 570px;
  }
}
@media only screen and (min-width: 900px) {
  .container-sm {
    max-width: 860px;
  }
}
@media only screen and (min-width: 1200px) {
  .container-sm {
    max-width: 1150px;
  }
}
@media only screen and (min-width: 1800px) {
  .container-sm {
    max-width: 1400px;
  }
}

.container-md {
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
}
@media only screen and (min-width: 900px) {
  .container-md {
    max-width: 860px;
  }
}
@media only screen and (min-width: 1200px) {
  .container-md {
    max-width: 1150px;
  }
}
@media only screen and (min-width: 1800px) {
  .container-md {
    max-width: 1400px;
  }
}

.container-lg {
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
}
@media only screen and (min-width: 1200px) {
  .container-lg {
    max-width: 1150px;
  }
}
@media only screen and (min-width: 1800px) {
  .container-lg {
    max-width: 1400px;
  }
}

.container-xl {
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
}
@media only screen and (min-width: 1800px) {
  .container-xl {
    max-width: 1400px;
  }
}