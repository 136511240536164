.modal {
    &-background {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        z-index: 9000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-container {
        display: flex;
        flex-direction: column;

        border: 1px solid var(--color-border-primary);
        border-radius: var(--border-radius);
        background-color: var(--color-bg-primary);

        max-height: 90vh;
        max-width: 90vw;
        overflow-y: auto;
    }

    &-header {
        padding: 20px;
    }

    &-title {
        font-size: 1.7rem;
        font-weight: 600;
    }

    &-body {
        padding: 10px 20px;

        &-row {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            &-item {
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &-footer {}

    &-actions {
        display: flex;
        justify-content: flex-end;

        &-item {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
