.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.8rem 1.1rem;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  font-size: 1.4rem;
  border-radius: $border-radius-default;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &[disabled] {
    cursor: not-allowed;
  }

  &-primary {
    color: #fff;
    background-color: #556ee6;
    border-color: #556ee6;

    &:hover {
      color: #fff;
      background-color: #485ec4;
      border-color: #4458b8;
    }

    &:active {
      color: #fff;
      background-color: #4458b8;
      border-color: #4053ad;
    }

    &:focus {
      color: #fff;
      background-color: #485ec4;
      border-color: #4458b8;
      box-shadow: 0 0 0 0.25rem rgba(111, 132, 234, 0.5);
    }
  }

  &-secondary {
    color: #fff;
    background-color: #74788d;
    border-color: #74788d;

    &:hover {
      color: #fff;
      background-color: #636678;
      border-color: #5d6071;
    }

    &:active {
      color: #fff;
      background-color: #5d6071;
      border-color: #575a6a;
    }

    &:focus {
      color: #fff;
      background-color: #636678;
      border-color: #5d6071;
      box-shadow: 0 0 0 0.25rem rgba(137, 140, 158, 0.5);
    }
  }

  &-success {
    color: #fff;
    background-color: #34c38f;
    border-color: #34c38f;

    &:hover {
      color: #fff;
      background-color: #2ca67a;
      border-color: #2a9c72;
    }

    &:active {
      color: #fff;
      background-color: #2a9c72;
      border-color: #27926b;
    }

    &:focus {
      color: #fff;
      background-color: #2ca67a;
      border-color: #2a9c72;
      box-shadow: 0 0 0 0.25rem rgba(82, 204, 160, 0.5);
    }
  }

  &-warning {
    color: #fff;
    background-color: #f1b44c;
    border-color: #f1b44c;

    &:hover {
      color: #fff;
      background-color: #cd9941;
      border-color: #c1903d;
    }

    &:active {
      color: #fff;
      background-color: #c1903d;
      border-color: #b58739;
    }

    &:focus {
      color: #fff;
      background-color: #cd9941;
      border-color: #c1903d;
      box-shadow: 0 0 0 0.25rem rgba(243, 191, 103, 0.5);
    }
  }

  &-danger {
    color: #fff;
    background-color: #f46a6a;
    border-color: #f46a6a;

    &:hover {
      color: #fff;
      background-color: #cf5a5a;
      border-color: #c35555;
    }

    &:active {
      color: #fff;
      background-color: #c35555;
      border-color: #b75050;
    }

    &:focus {
      color: #fff;
      background-color: #cf5a5a;
      border-color: #c35555;
      box-shadow: 0 0 0 0.25rem rgba(246, 128, 128, 0.5);
    }
  }

  &-info {
    color: #fff;
    background-color: #50a5f1;
    border-color: #50a5f1;

    &:hover {
      color: #fff;
      background-color: #448ccd;
      border-color: #4084c1;
    }

    &:active {
      color: #fff;
      background-color: #4084c1;
      border-color: #3c7cb5;
    }

    &:focus {
      color: #fff;
      background-color: #448ccd;
      border-color: #4084c1;
      box-shadow: 0 0 0 0.25rem rgba(106, 179, 243, 0.5);
    }
  }

  &-link {
    color: #556ee6;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: #4458b8;
      text-decoration: underline;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(85, 110, 230, 0.25);
    }
  }

  &-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;

    &:hover {
      color: #fff;
      background-color: #2c3136;
      border-color: #2a2e33;
    }

    &:active {
      color: #fff;
      background-color: #2a2e33;
      border-color: #272c30;
    }

    &:focus {
      color: #fff;
      background-color: #2c3136;
      border-color: #2a2e33;
      box-shadow: 0 0 0 0.25rem rgba(82, 88, 93, 0.5);
    }
  }

  &-light {
    color: #000;
    background-color: #eff2f7;
    border-color: #eff2f7;

    &:hover {
      color: #000;
      background-color: #f1f4f8;
      border-color: #f1f3f8;
    }

    &:active {
      color: #000;
      background-color: #f2f5f9;
      border-color: #f1f3f8;
    }

    &:focus {
      color: #000;
      background-color: #f1f4f8;
      border-color: #f1f3f8;
      box-shadow: 0 0 0 0.25rem rgba(203, 206, 210, 0.5);
    }
  }

  &-outline {
    &-primary {
      color: #556ee6;
      border-color: #556ee6;

      &:hover {
        color: #fff;
        background-color: #556ee6;
        border-color: #556ee6;
      }

      &:active {
        color: #fff;
        background-color: #556ee6;
        border-color: #556ee6;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(85, 110, 230, 0.5);
      }
    }

    &-secondary {
      color: #74788d;
      border-color: #74788d;

      &:hover {
        color: #fff;
        background-color: #74788d;
        border-color: #74788d;
      }

      &:active {
        color: #fff;
        background-color: #74788d;
        border-color: #74788d;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(116, 120, 141, 0.5);
      }
    }

    &-success {
      color: #34c38f;
      border-color: #34c38f;

      &:hover {
        color: #fff;
        background-color: #34c38f;
        border-color: #34c38f;
      }

      &:active {
        color: #fff;
        background-color: #34c38f;
        border-color: #34c38f;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(52, 195, 143, 0.5);
      }
    }

    &-warning {
      color: #f1b44c;
      border-color: #f1b44c;

      &:hover {
        color: #fff;
        background-color: #f1b44c;
        border-color: #f1b44c;
      }

      &:active {
        color: #fff;
        background-color: #f1b44c;
        border-color: #f1b44c;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(241, 180, 76, 0.5);
      }
    }

    &-danger {
      color: #f46a6a;
      border-color: #f46a6a;

      &:hover {
        color: #fff;
        background-color: #f46a6a;
        border-color: #f46a6a;
      }

      &:active {
        color: #fff;
        background-color: #f46a6a;
        border-color: #f46a6a;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(244, 106, 106, 0.5);
      }
    }

    &-info {
      color: #50a5f1;
      border-color: #50a5f1;

      &:hover {
        color: #fff;
        background-color: #50a5f1;
        border-color: #50a5f1;
      }

      &:active {
        color: #fff;
        background-color: #50a5f1;
        border-color: #50a5f1;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(80, 165, 241, 0.5);
      }
    }

    &-dark {
      color: #343a40;
      border-color: #343a40;

      &:hover {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
      }

      &:active {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.5);
      }
    }

    &-light {
      color: #212529;
      border-color: #eff2f7;

      &:hover {
        color: #000;
        background-color: #eff2f7;
        border-color: #eff2f7;
      }

      &:active {
        color: #000;
        background-color: #eff2f7;
        border-color: #eff2f7;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(239, 242, 247, 0.5);
      }
    }
  }

  &-icon {

    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 2px;

    cursor: pointer;

    &-s {
      width: 24px;
      height: 24px;
    }

    &-s > &-svg {
      width: 16px;
      height: 16px;
    }

    &-m {
      width: 32px;
      height: 32px;
    }

    &-m > &-svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: rgba(107, 107, 107, 0.08);
    }

    &:active {
      background-color: rgba(107, 107, 107, 0.12);
    }

    &[disabled] {
      cursor: default;
      color: rgba(150, 150, 150, 0.7) !important;

      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: transparent;
      }
    }

    &-round {
      border-radius: 50%;
    }
  }

  &-fill {
    display: block;
    width: 100%;
  }

  &-items > & {
    margin-bottom: 12px;
    margin-left: 8px;
  }

  & + & {
    margin-bottom: 8px;
    margin-left: 8px;
  }

}