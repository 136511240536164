.crm {

  margin: 14px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;

    &-count {
      margin-left: 10px;
    }
  }

  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
