.form {

  &-group {
    margin-bottom: 1.6rem;
  }

  &-label {
    padding-bottom: 1.2rem;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }

  &-control {
    display: block;
    width: 100%;
    padding: 0.8rem 1.1rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:active {
      color: #495057;
      background-color: #fff;
      border-color: #b9bfc4;
    }

    &-color {
      width: 5rem;
      height: 4rem;
      padding: 0.5rem;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: #495057 !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &-select {
    display: block;
    width: 100%;
    padding: 0.8rem 1.1rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    //background-image: url('');
    //background-repeat: no-repeat;
    //background-position: right 0.75rem center;
    //background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.4rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;

    &:focus {
      border-color: #b9bfc4;
      outline: 0;
      box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, 0.25);
    }
  }
}