.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 1005;
  min-width: 10rem;
  padding: 0.5rem 0;
  border-radius: 4px;
  color: $text-color;
  background: #fff;
  border: 0 solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.18);
  margin-top: 2px;

  &-slide {
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 2rem;
    color: $text-color;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #eff2f7;
  }
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0)
  }
  0% {
    transform: translateY(10px)
  }
}