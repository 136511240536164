.auth {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-logo {
    margin: 24px 0;
  }

  &-form {
    width: 350px;

    & > div {
      margin-bottom: 12px;
    }

    &-header {
      text-align: right;
    }

    &-body {
      padding: 16px;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
    }
  }

  &-persist {

    margin-bottom: 12px;
    display: flex;
    align-items: center;

    & input[type="checkbox"] {
      height: 16px;
      width: 16px;
      margin: 0 5px 2px 2px;
    }

    & label {
      margin: 0;
    }
  }
}