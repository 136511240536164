.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.5px solid #d0d7de;
  border-radius: 0.4rem;
  margin-bottom: 24px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  &-title {
    padding: 15px;
    font-size: 1.5rem;
    border-bottom: 0.5px solid #d0d7de;
    background-color: #f6f8fa;
    font-weight: 500;
  }

  &-body {
    flex: 1 1 auto;
    padding: 1.4rem;
  }
}
