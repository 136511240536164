.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 2px;

  &-s {
    width: 24px;
    height: 24px;
  }

  &-s > &-svg {
    width: 16px;
    height: 16px;
  }

  &-m {
    width: 32px;
    height: 32px;
  }

  &-m > &-svg {
    width: 24px;
    height: 24px;
  }

  &[disabled] {
    color: rgba(150, 150, 150, 0.7) !important;
  }
}