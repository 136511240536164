.badge {
  position: relative;
  display: inline-block;
  padding: 0.6em 0.4em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 0.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  border: 1px solid transparent;

  &-primary {
    color: #fff;
    background-color: #556ee6;
  }
  &-success {
    color: #fff;
    background-color: #34c38f;
  }
  &-warning {
    color: #fff;
    background-color: #f1b44c;
  }
  &-danger {
    color: #fff;
    background-color: #f46a6a;
  }
  &-info {
    color: #fff;
    background-color: #50a5f1;
  }
  &-dark {
    color: #fff;
    background-color: #343a40;
  }

  &-soft {
    &-primary {
      color: #556ee6;
      background-color: rgba(85, 110, 230, 0.18);
    }
    &-success {
      color: #34c38f;
      background-color: rgba(52, 195, 143, 0.18);
    }
    &-warning {
      color: #f1b44c;
      background-color: rgba(241, 180, 76, 0.18);
    }
    &-danger {
      color: #f46a6a;
      background-color: rgba(244, 106, 106, 0.18);
    }
    &-info {
      color: #50a5f1;
      background-color: rgba(80, 165, 241, 0.18);
    }
    &-dark {
      color: #343a40;
      background-color: rgba(52, 58, 64, 0.18);
    }
  }

  &-rounded {
    border-radius: 30px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  &-items > & {
    margin-bottom: 12px;
    margin-left: 8px;
  }

}